<template>
  <div class="personCenter">
    <div class="userInfo">
      <img :src="info.avatarUrl || require('../../assets/images/touxiang.png')" />
      <div class="nickName">{{info.nickName || '用户名'}}</div>
    </div>
    <div class="navMenuList">
      <div
        :class="['navMentItem ',item.path === currentNavMenu ? 'active' : '']"
        v-for="(item, index) in navMenuList"
        :key="item.path"
        @click="changeNavMenu(item.path)"
        @mouseenter="selectStyle(item)"
        @mouseout="outStyle(item)">
      <div class="flex_ac">
       <img :src="item.pictureTwo" v-if="item.path === pictureNavMen || item.path === currentNavMenu" />
        <img :src="item.pictureOne" v-else />
        <div>{{item.title}}</div>
      </div>
       <div v-show="index === 6"
            :class="['navMentItem flex_ac setUp',items.path === vipMenu ? 'activeVip' : '']"
            v-for="items in myMemberList"
            :key="items.path"
            @click.stop="vipchangeNavMenu(items.path)"
            @mouseenter="vipselectStyle(items)"
            @mouseout="vipoutStyle(items)">
            <div class="setUpColor flex_ac">
              <img :src="items.pictureTwo" v-if="items.path === vipPictureNavMen || items.path === vipMenu" />
              <img :src="items.pictureOne" v-else />
              <span :class="items.path === vipMenu ? 'activeVip' : ''">{{items.title}}</span>
            </div>
        </div>
        <!-- <el-collapse-transition v-show="index === 6">
          <div v-if="isMyember" class="setUp">
            <div
              :class="['navMentItem flex_ac',item.path === vipMenu ? 'activeVip' : '']"
              v-for="item in myMemberList"
              :key="item.path"
              @click="vipchangeNavMenu(item.path)"
              @mouseenter="vipselectStyle(item)"
              @mouseout="vipoutStyle(item)">
            <img :src="item.pictureTwo" v-if="item.path === vipPictureNavMen || item.path === vipMenu" />
            <img :src="item.pictureOne" v-else />
            {{item.title}}
            </div>
          </div>
        </el-collapse-transition> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      currentNavMenu: 'myTracks',
      pictureNavMen: 'myTracks',
      vipMenu: '',
      vipPictureNavMen: '',
      isMyember: false,
      myMemberList: [{
        title: '会员',
        path: 'vip',
        pictureOne: require('../../assets/images/iconPersonal/fuwu.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/fuwu.png')
      },
      {
        title: '信任与保障',
        path: 'guarantee',
        pictureOne: require('../../assets/images/iconPersonal/xinren.png'),
        pictureTwo: require('../../assets/images/iconPersonal/xinren.s.png')
      },
      {
        title: '用户协议',
        path: 'user_agreement',
        pictureOne: require('../../assets/images/iconPersonal/xieyi.png'),
        pictureTwo: require('../../assets/images/iconPersonal/xieyi.s.png')
      }],
      navMenuList: [{
        title: '基本信息',
        path: 'myTracks',
        pictureOne: require('../../assets/images/iconPersonal/xinxi.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/xinxi.s.png')
      },
      {
        title: '发布项目',
        path: 'publishProject',
        pictureOne: require('../../assets/images/iconPersonal/fabu.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/fabu.s.png')
      },
      {
        title: '我的项目',
        path: 'myProject',
        pictureOne: require('../../assets/images/iconPersonal/xiangmu.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/xiangmu.s.png')
      },
      {
        title: '我的投标',
        path: 'myTender',
        pictureOne: require('../../assets/images/iconPersonal/toubiao.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/toubiao.s.png')
      },
      {
        title: '我的关注',
        path: 'myFocus',
        pictureOne: require('../../assets/images/iconPersonal/guanzhu.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/guanzhu.s.png')
      },
      {
        title: '我的评价',
        path: 'myComment',
        pictureOne: require('../../assets/images/iconPersonal/pingjia.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/pingjia.s.png')
      },
      {
        title: '会员中心',
        path: 'myMember',
        pictureOne: require('../../assets/images/iconPersonal/huiyuan.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/huiyuan.s.png')
      },
      {
        title: '意见反馈',
        path: 'feedback',
        pictureOne: require('../../assets/images/iconPersonal/yijian.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/yijian.s.png')
      },
      {
        title: '设置',
        path: 'setUp',
        pictureOne: require('../../assets/images/iconPersonal/shezhi.d.png'),
        pictureTwo: require('../../assets/images/iconPersonal/shezhi.s.png')
      }],
      info: {}
    }
  },
  methods: {
    changeNavMenu (path) {
      this.currentNavMenu = path
      if (this.currentNavMenu === 'myMember') {
        // this.isMyember = !this.isMyember
        this.vipMenu = 'vip'
        // console.log(this.vipMenu)
        localStorage.setItem('vipMenu', this.vipMenu)
        this.$router.push({name: 'myMember', query: {type: this.vipMenu}})
      } else {
        this.$router.push({path})
      }
    },
    selectStyle(item) {
      this.pictureNavMen = item.path
    },
    outStyle(item) {
      this.pictureNavMen = ''
    },
    vipchangeNavMenu (path) {
      // console.log('dbsjf', path)
      this.vipMenu = path
      localStorage.setItem('vipMenu', this.vipMenu)
      this.$router.push({name: 'myMember', query: {type: this.vipMenu}})
      // this.vipMenu = ''
    },
    vipselectStyle(item) {
      this.vipPictureNavMen = item.path
    },
    vipoutStyle(item) {
      this.vipPictureNavMen = ''
    }
  },
  mounted() {
    window.addEventListener('setItemEvent', (e) => {
      this.info = JSON.parse(localStorage.getItem('userInfo'))
    })
  },
  created() {
    this.info = JSON.parse(localStorage.getItem('userInfo'))
    const actionRouter = this.$router.history.current.name;
    const action = this.navMenuList.find((value, index, arr) => {
      return value.path === actionRouter;
    })
    this.currentNavMenu = action.path;
  }
}
</script>
<style lang="scss" scoped>
  .personCenter{
    width: 240px;
    background: #fff;
    text-align: center;
    .setUp{
      background: #fff  !important;
      .setUpColor{
         margin-left: 30px;
         color: #333333;
         &.activeVip{
          color: $button-block;
        }
      }
    }
    .userInfo{
      padding: 30px 0;
      img{
        width: 88px;
        height: 88px;
        object-fit: cover;
        // background: #DDDDDD;
        border: 1px solid $button-block;
        border-radius: 50%;
        margin-bottom: 15px;
      }
      .nickName{
        color: #333333;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .navMenuList{
      .navMentItem{
        // height: 48px;
        // padding: 6px 0;
        line-height: 48px;
        font-size: 16px;
        // padding-left: 21px;
        img{
          padding: 0 15px 0 55px;
        }
        &.active{
          background: #EDF5FF;
          color: $button-block;
        }
        &:hover:not(.active){
          color: $button-block;
          cursor: pointer;
        }
      }
    }
  }
</style>
