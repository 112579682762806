import { render, staticRenderFns } from "./publishDisplay.vue?vue&type=template&id=cc013986&scoped=true&"
import script from "./publishDisplay.vue?vue&type=script&lang=js&"
export * from "./publishDisplay.vue?vue&type=script&lang=js&"
import style0 from "./publishDisplay.vue?vue&type=style&index=0&id=cc013986&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc013986",
  null
  
)

export default component.exports