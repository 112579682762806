<template>
  <div class="myTracks flex">
    <PersonCenter />
    <div class="articleContainer">
      <PublishDisplay :obj="publishObj" />
      <router-view />
    </div>
  </div>
</template>

<script>
import PersonCenter from './personCenter'
import PublishDisplay from './publishDisplay'
import {statistics} from '@/api/user/user'
export default {
  name: 'index',
  components: {
    PersonCenter,
    PublishDisplay
  },
  data() {
    return {
      publishObj: {},
      userInfo: {}
    }
  },
  mounted() {
    // this.myUser()
    this.statisticsModel()
  },
  methods: {
    // myUser() {
    //   myInfo().then(res => {
    //     // console.log(res.data)
    //     this.userInfo = res.data
    //   })
    // },
    statisticsModel() {
      statistics().then(res => {
        this.publishObj = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .myTracks{
    width: 1200px;
    margin: 12px auto;
    .articleContainer{
      flex: 1;
      margin-left: 12px;
    }
  }
</style>
