<template>
  <div class="PublishDisplay flex_jcsb_ac">
    <div class="Publish flex" v-for="(item,index) in PublishDisplayList" :key="index">
      <img :src="item.image" />
      <div class="paddingLeft">
        <div class="name">{{item.name}}</div>
        <div class="num">{{item.num}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublishDisplay',
  props: {
    obj: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    obj(val) {
      this.PublishDisplayList[0].num = val.top.project_pass
      this.PublishDisplayList[1].num = val.top.project_check
      this.PublishDisplayList[2].num = val.top.project_fail
      this.PublishDisplayList[3].num = val.top.tender_count
    }
  },
  data() {
    return {
      PublishDisplayList: [
        {
          image: require('../../assets/images/icon/fabu.png'),
          name: '已发布',
          num: ''
        },
        {
          image: require('../../assets/images/icon/shenhe.png'),
          name: '审核中',
          num: ''
        },
        {
          image: require('../../assets/images/icon/weitongguo.png'),
          name: '未通过',
          num: ''
        },
        {
          image: require('../../assets/images/icon/toubiap.png'),
          name: '投标记录',
          num: ''
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .PublishDisplay{
    background: #fff;
    padding: 27px 40px;
    .Publish:nth-child(4){
      padding-right: 0;
      border-right: none;
    }
    .Publish{
      border-right: 1px solid #EEE;
      padding-right: 48px;
      margin: auto;
      .paddingLeft{
        margin-left: 14px;
        .name{
          font-size: 16px;
          color: #999;
        }
        .num{
          margin-top: 8px;
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
  }
</style>
